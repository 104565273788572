import { styled } from "../../theme";

export const ModuleViewerContainer = styled.div``;

export const StyledIframe = styled.iframe`
  height: 100vh;
  width: 100%;
  border: none;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.primary};
  padding: 1vh 0%;
`;

export const StyledButton = styled.button`
  font-family: ${({ theme }) => theme.font.main.family};
  font-weight: ${({ theme }) => theme.font.main.weight};
  font-stretch: ${({ theme }) => theme.font.main.stretch};
  color: ${({ theme }) => theme.color.primary};
  border: none;
  border-radius: 25px;
  width: 10%;
  padding: 0.8%;
  background-color: #fff;
  cursor: pointer;
`;
