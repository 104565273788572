import React from "react";

import { MediaImage } from "../../types";
import { Title } from "../";
import { CardContainer, TitleContainer, ButtonContainer, StyledButton } from "./Card.style";

interface Props {
  bgImage: MediaImage;
  bgColor: string;
  title: string | JSX.Element;
  buttonText: string;
  isDisabled?: boolean;
  moduleUrl: string;
  onClick: (event?: React.MouseEvent) => void;
}

const Card: React.FC<Props> = (props: Props) => {
  const onClickButton = (event: React.MouseEvent) => {
    event.preventDefault();
    props.onClick(event);
  };

  return (
    <CardContainer className="card-container">
      <TitleContainer disabled={props.isDisabled} bgColor={props.bgColor}>
        <Title customClass="card-title" level={2}>
          {props.title}
        </Title>
      </TitleContainer>
      <ButtonContainer className={props.isDisabled ? "disabled" : ""} bgImageUrl={props.bgImage.url}>
        <StyledButton onClick={onClickButton} color={props.bgColor} disabled={props.isDisabled}>
          {(props.isDisabled ? "bientôt" : props.buttonText).toLocaleUpperCase()}
        </StyledButton>
      </ButtonContainer>
    </CardContainer>
  );
};

export default Card;
