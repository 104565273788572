import { DEFAULT_LANGUAGE } from "../i18n";
import { User } from "../types";

const STORAGE_KEYS = {
  USER_INFOS: "userInfos",
  SELECTED_LANGUAGE: "selectedLanguage",
} as const;

type StorageKeys = typeof STORAGE_KEYS[keyof typeof STORAGE_KEYS];

export interface StorageServiveInterface {
  setUser: (user: User) => void;
  removeUser: () => void;
  getUser: () => User | null;
  clearData: () => void;
  getUserLanguage: () => string;
}
const setItem = (key: StorageKeys, value: string) => {
  sessionStorage.setItem(key, value);
};

const setUser = (user: User) => {
  user && setItem(STORAGE_KEYS.USER_INFOS, JSON.stringify(user));
};

const getUser = (): User | null => {
  const user = sessionStorage.getItem(STORAGE_KEYS.USER_INFOS);

  return user ? (JSON.parse(user) as User) : null;
};
const getUserLanguage = (): string => {
  const lang = sessionStorage.getItem(STORAGE_KEYS.SELECTED_LANGUAGE);

  return lang ? lang : DEFAULT_LANGUAGE;
};

const removeUser = () => {
  sessionStorage.removeItem(STORAGE_KEYS.USER_INFOS);
};

const clearData = () => {
  Object.values(STORAGE_KEYS).map((key) => sessionStorage.removeItem(key));
};

export default { setUser, getUser, removeUser, clearData, getUserLanguage } as StorageServiveInterface;
