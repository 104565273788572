import React from "react";

import { H1, H2 } from "./Title.style";

interface TitleInterface {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  customClass?: string;
}

const Title: React.FC<TitleInterface> = (props) => {
  if (props.level === 1) {
    return <H1 className={`title-level-${props.level} ${props.customClass || ""} `}>{props.children}</H1>;
  }

  if (props.level === 2) {
    return <H2 className={`title-level-${props.level} ${props.customClass || ""} `}>{props.children}</H2>;
  }

  return null;
};

export default Title;
