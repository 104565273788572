import { StorageServiveInterface } from "../service/storage.service";
import { UserServiceInterface } from "../service/user.service";
import { User } from "../types";

interface CoreInput {
  UserService: UserServiceInterface;
  StorageService: StorageServiveInterface;
}

interface CoreInterface {
  setOwnUser: (encryptedData: string) => Promise<User | undefined>;
}

const core = ({ UserService, StorageService }: CoreInput): CoreInterface => {
  const setOwnUser = async (encryptedData: string) => {
    const storedUser = StorageService.getUser();

    if (storedUser) {
      return storedUser;
    }

    try {
      const user = await UserService.getOwnUser(encryptedData);
      StorageService.setUser(user);

      return user;
    } catch (err) {
      return;
    }
  };

  return { setOwnUser };
};

export default core;
