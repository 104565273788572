import React from "react";
import querystring from "query-string";

export const buildTitle = (
  splitTitle: string[],
  opts = {
    toUpperCase: false,
  },
): JSX.Element => {
  const titleFragments = [...(opts.toUpperCase ? splitTitle.join("///").toLocaleUpperCase().split("///") : splitTitle)];

  const lastFragment = titleFragments.pop();

  const lastElement = <React.Fragment>{lastFragment}</React.Fragment>;

  const firstElements = titleFragments.map((title, index) => (
    <React.Fragment key={index}>
      {title}
      <br />
    </React.Fragment>
  ));

  return React.createElement("span", undefined, [...firstElements, lastElement]);
};

export const getParamsFromUrl = (url: string): querystring.ParsedQuery => {
  const search = url.slice(url.indexOf("?"));
  return querystring.parse(search);
};

export const getSingleUrlParamFromKey = (url: string, key: string): string => {
  const params = getParamsFromUrl(url);

  const targetedParam = params[key] || "";

  if (typeof targetedParam !== "string") {
    return targetedParam[0] || "";
  }

  return targetedParam;
};
