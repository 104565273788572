import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import content from "./locales";
export const getCurrentLang = () => {
  return i18n.language;
};
export const SUPPORTED_LANGUAGE = ["fr", "en"];
export const DEFAULT_LANGUAGE = "fr";

export const changeLang = (lang: string) => {
  document.documentElement.setAttribute("lang", lang);
  void i18n.changeLanguage(lang);
};

void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["querystring", "localStorage"],
      lookupLocalStorage: "selectedLanguage",
      lookupQuerystring: "language",
    },
    resources: content,
    fallbackLng: DEFAULT_LANGUAGE,
    supportedLngs: SUPPORTED_LANGUAGE,
    debug: true,
    load: "languageOnly",
    cleanCode: true,
    keySeparator: ".",
    interpolation: { escapeValue: false },
  });
export default i18n;
