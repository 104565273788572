import axios from "axios";

import { User } from "../types";
import CONSTANTS from "../consts";

export interface UserServiceInterface {
  getOwnUser: (encryptedData: string) => Promise<User>;
}

const getOwnUser = async (encryptedData: string): Promise<User | undefined> => {
  try {
    const result = await axios.get(`${CONSTANTS.API_URL}/user/encrypted/${encryptedData}`, {
      params: { applicationId: CONSTANTS.APPLICATION_ID },
    });

    return result.data as User;
  } catch (err) {
    throw new Error("Error while retrieving user.");
  }
};

export default { getOwnUser } as UserServiceInterface;
