import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { AppContainer } from "./App.style";
import { defaultTheme } from "./theme";
import { userCore } from "./core";
import { getSingleUrlParamFromKey } from "./utils";
import { User } from "./types";
import { Home, Unauthenticated } from "./pages";
import { Spinner } from "./components";
import consts from "./consts";

const { USER_DATA_KEY } = consts;

const App: React.FC = () => {
  const [isFetching, setFetching] = useState(false);
  const [user, setUser] = useState<User>();

  const initialiseUser = async (encryptedData: string): Promise<void> => {
    setFetching(true);
    try {
      const user = await userCore.setOwnUser(encryptedData);

      if (user) {
        setUser(user);
      }
    } finally {
      setFetching(false);

      if (window.location.search.includes(USER_DATA_KEY)) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(USER_DATA_KEY);

        window.location.search = searchParams.toString();
      }
    }
  };

  useEffect(() => {
    void initialiseUser(getSingleUrlParamFromKey(window.location.href, USER_DATA_KEY));
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      {process.env.REACT_APP_STAGE === "develop" ? (
        <AppContainer>
          <Home />
        </AppContainer>
      ) : (
        <AppContainer>{isFetching ? <Spinner /> : user?.email ? <Home /> : <Unauthenticated />}</AppContainer>
      )}
    </ThemeProvider>
  );
};

export default App;
