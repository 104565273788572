import { styled } from "../../theme";

export const H1 = styled.h1`
  font-size: 2.25em;
  line-height: 1;
  margin: 0;

  &.header-title {
    font-size: 2.75em;
    text-align: right;
    color: ${({ theme }) => theme.color.secondary};
  }
`;

export const H2 = styled.h2`
  font-size: 1.88em;
  line-height: 1;
  margin: 0;

  &.card-title {
    color: #ffffff;
    text-align: center;
  }
`;
