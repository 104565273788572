import { styled } from "../../theme";

const titleHeightInPercents = 25;

export const CardContainer = styled.div`
  height: 100%;
`;

export const TitleContainer = styled.div<{ bgColor: string; disabled?: boolean }>`
  background-color: ${({ bgColor, disabled }) => (disabled ? "#818181" : bgColor)};
  height: ${titleHeightInPercents}%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled.div<{ bgImageUrl: string; disabled?: boolean }>`
  background-image: url(${({ bgImageUrl }) => bgImageUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  height: ${100 - titleHeightInPercents}%;
  align-items: flex-end;
  justify-content: center;

  &.disabled {
    filter: grayscale(100%);
  }
`;

export const StyledButton = styled.button<{ color: string; disabled?: boolean }>`
  font-family: ${({ theme }) => theme.font.main.family};
  font-weight: ${({ theme }) => theme.font.main.weight};
  font-stretch: ${({ theme }) => theme.font.main.stretch};
  color: ${({ color, disabled }) => (disabled ? "#ffffff" : color)};
  font-size: 1.3em;
  background-color: ${({ disabled }) => (disabled ? "transparent" : "#ffffff")};
  border: none;
  border-radius: 25px;
  width: 55%;
  padding: 2%;
  margin-bottom: 5%;
  cursor: pointer;
`;
