import styled from "styled-components";

export const HeaderWrapper = styled.header`
  padding-bottom: 1.5em;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  .title {
    justify-content: end;
    margin-bottom: 0;
  }
`;

export const Logo = styled.img`
  max-width: 25%;
`;
