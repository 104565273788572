import baseStyled, { ThemedStyledInterface } from "styled-components";

export const defaultTheme = {
  font: {
    main: { family: "TTLakes", weight: "bold", stretch: "condensed" },
  },
  color: {
    primary: "#03326C",
    secondary: "#EE6700",
  },
};

export type DefaultTheme = typeof defaultTheme;
export const styled = baseStyled as ThemedStyledInterface<DefaultTheme>;
export type StyleSheet = { [key: string]: React.CSSProperties };
