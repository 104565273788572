import React from "react";

import { MediaImage } from "../../types";
import { HeaderRow, HeaderWrapper, Logo } from "./Header.style";
import Title from "../Title/Title";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { SUPPORTED_LANGUAGE } from "../../i18n";

interface Props {
  logo: MediaImage;
  title: string | JSX.Element;
  customClass?: string;
}

const Header: React.FC<Props> = (props: Props) => {
  return (
    <HeaderWrapper className={`header-container ${props.customClass || ""}`}>
      <HeaderRow className="langage-selector">
        <Logo id="logo-header" src={props.logo.url} alt={props.logo.alt} />
        <LanguageSelector languageList={SUPPORTED_LANGUAGE} />
      </HeaderRow>
      <HeaderRow className="title">
        <Title level={1} customClass={"header-title"}>
          {props.title}
        </Title>
      </HeaderRow>
    </HeaderWrapper>
  );
};

export default Header;
