import { Content } from "./types";

const content: Content = {
  logo: { type: "image", url: "./assets/tubesca/logo.jpg", alt: "logo" },
  title: "content:title",
  modules: [
    {
      bgImage: {
        type: "image",
        url: "./assets/tubesca/box-backgrounds/bg0.jpg",
        alt: "content:modules.0.bgImage.alt",
      },
      bgColor: "#4383c1",
      buttonText: "content:modules.0.buttonText",
      title: "content:modules.0.title",
      isDisabled: false,
      url: "content:modules.0.url",
    },
    {
      bgImage: {
        type: "image",
        url: "./assets/tubesca/box-backgrounds/bg1.jpg",
        alt: "content:modules.1.bgImage.alt",
      },
      bgColor: "#03326c",
      buttonText: "content:modules.1.buttonText",
      title: "content:modules.1.title",
      isDisabled: false,
      url: "content:modules.1.url",
    },
    {
      bgImage: {
        type: "image",
        url: "./assets/tubesca/box-backgrounds/bg2.jpg",
        alt: "content:modules.2.bgImage.alt",
      },
      bgColor: "#e01e2e",
      buttonText: "content:modules.2.buttonText",
      title: "content:modules.2.title",
      isDisabled: false,
      url: "content:modules.2.url",
    },
    {
      bgImage: {
        type: "image",
        url: "./assets/tubesca/box-backgrounds/bg3.jpg",
        alt: "content:modules.3.bgImage.alt",
      },
      bgColor: "#fdbc2c",
      buttonText: "content:modules.3.buttonText",
      title: "content:modules.3.title",
      isDisabled: false,
      url: "content:modules.3.url",
    },
  ],
};

export default content;
