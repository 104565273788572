import React from "react";
import { FooterContainer } from "./Footer.style";

interface Props {
  customClass?: string;
}

const Footer: React.FC<Props> = (props) => {
  return <FooterContainer className={`footer ${props.customClass || ""}`}>{props.children}</FooterContainer>;
};

export default Footer;
