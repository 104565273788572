import React from "react";
import { useTranslation } from "react-i18next";
import storageService from "../../service/storage.service";
import { ModuleViewerContainer, StyledIframe, StyledButton, ButtonContainer } from "./ModuleViewer.style";

interface Props {
  moduleUrl: string;
  onClickClose: (event?: React.MouseEvent) => void;
}

const ModuleViewer: React.FC<Props> = (props) => {
  const { i18n } = useTranslation();
  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();
    props.onClickClose(event);
  };

  return (
    <ModuleViewerContainer>
      <ButtonContainer>
        <StyledButton onClick={onClick}>{i18n.language === "fr" ? "RETOUR" : "BACK"}</StyledButton>
      </ButtonContainer>
      <StyledIframe src={props.moduleUrl} />
    </ModuleViewerContainer>
  );
};

export default ModuleViewer;
