import React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(./assets/redirection_bg.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const Cloud = styled.div`
  height: 100%;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 10%,
    rgba(166, 166, 207, 0.03265056022408963) 20%,
    rgba(68, 68, 153, 0) 80%,
    rgba(255, 255, 255, 1) 90%
  );
`;

const Img = styled.div`
  height: 10%;
  width: 30%;
  position: absolute;
  top: 28%;
  left: 50%;
  transform: translate(-50%);
  border: none;
  :hover {
    cursor: pointer;
  }
`;

const Link = styled.a`
  cursor: pointer;
  display: inline-block;
  height: 100%;
  width: 50%;
`;

const Unauthenticated: React.FC = () => {
  return (
    <Container>
      <Cloud>
        <Img>
          <Link href="https://www.tubesca-comabi.com/fr/tubescacomabi_member" rel="noopener noreferrer" />
          <Link href="https://www.tubesca-comabi.international/en/tubescacomabi_member" rel="noopener noreferrer" />
        </Img>
      </Cloud>
    </Container>
  );
};

export default Unauthenticated;
