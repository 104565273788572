import { Grid, Container } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Footer, Header, Card, ModuleViewer } from "../components";
import content from "../content";
import { buildTitle } from "../utils";

const Home: React.FC = () => {
  const [moduleUrl, setModuleUrl] = useState("");

  const { t } = useTranslation();

  const onOpenModule = (url: string) => {
    setModuleUrl(url);
  };

  const onCloseModule = () => {
    setModuleUrl("");
  };

  return moduleUrl.length ? (
    <ModuleViewer onClickClose={onCloseModule} moduleUrl={moduleUrl} />
  ) : (
    <Container style={{ height: "100%" }}>
      <Header
        title={buildTitle(t(content.title, { returnObjects: true }), { toUpperCase: true })}
        logo={content.logo}
      />
      <Grid style={{ height: "75%" }} justifyContent={"center"} container spacing={2} alignItems="center">
        {content.modules.map((moduleDetails) => {
          return (
            <Grid key={moduleDetails.url} xs={12} md={3} style={{ height: "100%" }} item>
              <Card
                bgImage={moduleDetails.bgImage}
                bgColor={moduleDetails.bgColor}
                buttonText={t(moduleDetails.buttonText)}
                title={buildTitle(t(moduleDetails.title, { returnObjects: true }), { toUpperCase: true })}
                isDisabled={moduleDetails.isDisabled}
                moduleUrl={t(moduleDetails.url)}
                onClick={() => onOpenModule(t(moduleDetails.url))}
              />
            </Grid>
          );
        })}
      </Grid>
      <Footer />
    </Container>
  );
};

export default Home;
