import { styled } from "./theme";

export const AppContainer = styled.div`
  height: 100vh;

  font-family: ${({ theme }) => theme.font.main.family};
  font-weight: ${({ theme }) => theme.font.main.weight};
  font-stretch: ${({ theme }) => theme.font.main.stretch};
  color: ${({ theme }) => theme.color.primary};
`;
